import React from 'react';
import clsx from 'clsx';

import { CMSHomepageType } from '@utils/types/cmsHomepage';
import ImageWithLoader from '@components/_shared/imageWithLoader';

import styles from './styles.module.scss';

type GuestAppPreviewType = {
  guestAppPreview: CMSHomepageType['guestAppPreview'];
  guestAppPreviewImage1: CMSHomepageType['guestAppPreviewImage1'];
  guestAppPreviewImage2: CMSHomepageType['guestAppPreviewImage2'];
};

const GuestAppPreview = (props: GuestAppPreviewType) => {
  const { guestAppPreview, guestAppPreviewImage1, guestAppPreviewImage2 } =
    props;

  if (!guestAppPreview) return null;

  return (
    <div className={styles.guestAppPreview}>
      <div className={styles.guestAppPreview__textBlock}>
        <h2 className={styles.guestAppPreview__title}>
          {guestAppPreview.title}
        </h2>

        <div className={styles.guestAppPreview__description}>
          {guestAppPreview.description}
        </div>
      </div>

      {guestAppPreviewImage1 && guestAppPreviewImage2 && (
        <div className={styles.guestAppPreview__images}>
          <ImageWithLoader
            containerStyle={clsx([
              styles.guestAppPreview__image,
              styles.guestAppPreview__image__search,
            ])}
            src={guestAppPreviewImage1}
            alt="search preview"
            width="851px"
            height="530px"
          />

          <ImageWithLoader
            containerStyle={clsx([
              styles.guestAppPreview__image,
              styles.guestAppPreview__image__listing,
            ])}
            src={guestAppPreviewImage2}
            alt="listing preview"
            width="525px"
            height="530px"
          />
        </div>
      )}
    </div>
  );
};

export default GuestAppPreview;
