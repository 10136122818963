import React from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';

import { BaseComponentProps } from '@utils/types/baseComponents';
import { parseDateWithoutTimezone } from '@utils/dates';

import styles from './styles.module.scss';

type Props = {
  first_available_date: string;
} & BaseComponentProps;

const AvailableFrom = (props: Props) => {
  const { first_available_date, containerStyle } = props;

  return (
    <section className={clsx([styles.availableFrom, containerStyle])}>
      Available&nbsp;
      <strong>
        {format(parseDateWithoutTimezone(first_available_date), 'MMM d, yyyy')}
      </strong>
    </section>
  );
};

export default AvailableFrom;
