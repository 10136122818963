import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

import { BaseComponentProps } from '@utils/types/baseComponents';

import styles from './styles.module.scss';

type Props = {
  vimeoUrl?: string;
} & BaseComponentProps;

const VimeoBackground = (props: PropsWithChildren<Props>) => {
  const {
    children,
    containerStyle,
    vimeoUrl = 'https://player.vimeo.com/video/745756183',
  } = props;

  return (
    <section className={clsx([styles.vimeoBackground, containerStyle])}>
      <div className={styles.backgroundVideo}>
        <iframe
          src={`${vimeoUrl}?background=1&controls=0&h=efb1af0794&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
          width={`${1920 * 1.3}`}
          height={`1080`}
          allow="autoplay"
          allowFullScreen
          title="Rove Website Video.mp4"
        />
      </div>
      <div className={styles.backgroundVideo__overflow} />
      {children}
    </section>
  );
};

export default VimeoBackground;
