import { format as fnsFormat } from 'date-fns';

/**
 * See docs @link https://date-fns.org/v1.30.1/docs/format
 */
export function formatDate(date: Date, format: string) {
  return fnsFormat(date, format);
}

export function parseDateWithoutTimezone(date: string | Date) {
  const dt = new Date(date);
  const dtDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
  );
  return new Date(dtDateOnly);
}
