import React from 'react';
import clsx from 'clsx';

import { BaseComponentProps } from '@utils/types/baseComponents';

import styles from './styles.module.scss';

type LoaderProps = {
  type?: 'button' | 'content';
} & BaseComponentProps;

Loader.defaultProps = {
  type: 'button',
};

/**
 * Loader that fits button by default
 */
export function Loader(props: LoaderProps) {
  const { type, containerStyle } = props;

  return (
    <div
      className={clsx([
        styles.buttonLoader,
        containerStyle,
        type === 'content' && styles.forContent,
      ])}
    >
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

type FourDotsLoaderProps = {
  size?: number;
} & BaseComponentProps;

FourDotsLoader.defaultProps = {
  size: 16,
};

export function FourDotsLoader(props: FourDotsLoaderProps) {
  const { size, containerStyle } = props;
  const dotStyle = { width: size, height: size };

  return (
    <div className={clsx([styles.fourDotsLoader, containerStyle])}>
      <div style={dotStyle} />
      <div style={dotStyle} />
      <div style={dotStyle} />
      <div style={dotStyle} />
    </div>
  );
}

export function OverlayLoader({ containerStyle }: BaseComponentProps) {
  return (
    <div className={clsx([styles.overlayLoader, containerStyle])}>
      <Loader type="content" />
    </div>
  );
}
