import React, { Fragment, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { BaseComponentProps } from '@utils/types/baseComponents';
import { useIsMobile } from '@utils/hooks/useIsMobile';

import styles from './styles.module.scss';

type Props = {
  uniqueKey: string;
  items: any[];
  renderItem: (item: any, isCurrent: boolean) => React.ReactNode;
  noScrollOnMobile?: boolean;
} & BaseComponentProps;

const LandingAutoScrollableGallery = (props: Props) => {
  const {
    uniqueKey,
    items,
    renderItem,
    noScrollOnMobile = false,
    containerStyle,
  } = props;
  const [hovering, setHovering] = useState(false);
  const [currentMobileSlide, setCurrentMobileSlide] = useState<number>();
  const scrollIntervalRef = useRef<any>(null);
  const isMobile = useIsMobile();

  const clearScrollInterval = () => {
    clearInterval(scrollIntervalRef.current);
    scrollIntervalRef.current = null;
  };

  const handleScrollToRightInterval = () => {
    // no auto scroll if there are less than 3 items on desktop
    if (items.length <= 3 && !isMobile) return;

    const ribbonElement = document.getElementById(`${uniqueKey}-ribbon`);
    if (ribbonElement) {
      const oneSlideWidth = ribbonElement.scrollWidth / (items.length || 1);
      scrollIntervalRef.current = setInterval(() => {
        setCurrentMobileSlide(
          Math.floor((ribbonElement.scrollLeft * 1.2) / oneSlideWidth)
        );

        if (
          ribbonElement.scrollLeft !==
          ribbonElement.scrollWidth - ribbonElement.offsetWidth
        ) {
          ribbonElement.scrollTo(ribbonElement.scrollLeft + 1, 0);
        } else {
          ribbonElement.scrollTo(0, 0);
        }
      }, 25);
    }
  };

  useEffect(() => {
    if (hovering) {
      clearScrollInterval();
    } else {
      handleScrollToRightInterval();
    }
  }, [hovering]);

  useEffect(() => {
    if (isMobile && noScrollOnMobile) {
      clearScrollInterval();
    }
  }, [isMobile, noScrollOnMobile]);

  useEffect(
    () => () => {
      clearScrollInterval();
    },
    [hovering]
  );

  return (
    <div
      className={clsx([styles.autoScrollableGallery, containerStyle])}
      id={`${uniqueKey}-ribbon`}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {items.map((i, key) => (
        <Fragment key={`scrollable-item-${uniqueKey}-${key}`}>
          {renderItem(i, currentMobileSlide === key)}
        </Fragment>
      ))}
    </div>
  );
};

export default LandingAutoScrollableGallery;
