import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { BaseComponentProps } from '@utils/types/baseComponents';

import styles from './styles.module.scss';

export type TabType = {
  label: string | JSX.Element;
  value: string | number;
  onClick?: () => void;
};

type Props = {
  tabs: TabType[];
  initialTab?: TabType['value'];
  onChange?: (tab: TabType['value']) => void;
  variant?: 'default' | 'light';
} & BaseComponentProps;

const Tabs = (props: Props) => {
  const {
    tabs,
    initialTab = undefined,
    onChange,
    variant = 'default',
    containerStyle,
  } = props;
  const [selectedTab, setSelectedTab] = useState<TabType['value']>();

  useEffect(() => {
    if (initialTab !== selectedTab) setSelectedTab(initialTab);
  }, [initialTab]);

  const onTabClick = (tab: TabType) => {
    const { onClick } = tab;
    if (onClick) onClick();
    if (onChange) onChange(tab.value);
    setSelectedTab(tab.value);
  };

  return (
    <nav className={clsx([styles.tabs, containerStyle])}>
      {tabs.map((t) => (
        <div
          key={`tab-${t.value}`}
          onClick={() => onTabClick(t)}
          className={clsx([
            styles.tabs__tab,
            styles[variant],
            selectedTab === t.value && styles.selected,
          ])}
        >
          {t.label}
        </div>
      ))}
    </nav>
  );
};

export default Tabs;
