import React from 'react';
import NextImage, { ImageProps } from 'next/image';
import clsx from 'clsx';

import { BaseComponentProps } from '@utils/types/baseComponents';

import styles from './styles.module.scss';

type Props = {
  src: string;
  alt: ImageProps['alt'];
} & BaseComponentProps &
  ImageProps;

const ImageWithLoader = (props: Props) => {
  const { src, alt, containerStyle, ...nextImageProps } = props;

  return (
    <div className={clsx([styles.imageWithLoader, containerStyle])}>
      <NextImage
        src={src}
        alt={alt}
        layout="fill"
        placeholder="blur"
        blurDataURL={`/_next/image?url=${encodeURIComponent(src)}&q=1&w=16`}
        {...nextImageProps}
      />
    </div>
  );
};

export default ImageWithLoader;
