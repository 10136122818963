import React, { useMemo, useState } from 'react';

import Button from '@components/_shared/button';
import ButtonWithDropdown from '@components/_shared/buttonWithDropdown';
import { MarketType } from '@utils/types/city';
import {
  SegmentEventNamesEnum,
  SegmentEventPagesEnum,
  useAnalytics,
} from '@utils/hooks/useAnalytics';
import { DatesRange, FuzzyDatesOffset } from '@utils/types/calendar';
import { RangePicker } from '@components/_shared/rangePicker';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { ArrowButton } from '@assets/icons';

import styles from './styles.module.scss';

type Props = {
  markets: MarketType[];
  onSearch: (
    market: string,
    dates: DatesRange,
    fuzzyDatesOffset: FuzzyDatesOffset
  ) => void;
};

const HeroSearch = (props: Props) => {
  const { onSearch, markets } = props;
  const [market, setMarket] = useState<MarketType>();
  const [dateRange, setDateRange] = useState<DatesRange>({
    from: null,
    to: null,
  });
  const [fuzzyDatesOffset, setFuzzyDatesOffset] = useState<FuzzyDatesOffset>({
    from: 3,
    to: 3,
  });
  const isMobile = useIsMobile();

  const { trackEvent } = useAnalytics();

  const onMarketChange = (market: MarketType) => {
    trackEvent(SegmentEventNamesEnum.SELECT_MARKET_CLICKED, {
      PageName: SegmentEventPagesEnum.HOME_PAGE,
    });
    setMarket(market);
  };

  const marketsDropdown = useMemo(
    () => (
      <>
        {markets.map((m) => (
          <span key={m.slug} onClick={() => onMarketChange(m)}>
            {m.name}
          </span>
        ))}
      </>
    ),
    [markets.length]
  );

  const onSearchClick = () => {
    trackEvent(SegmentEventNamesEnum.SEARCH_CLICK, {
      PageName: SegmentEventPagesEnum.HOME_PAGE,
    });
    onSearch(market?.slug || '', dateRange, fuzzyDatesOffset);
  };

  return (
    <div className={styles.heroSearch}>
      <ButtonWithDropdown
        theme="dark"
        containerStyle={styles.heroSearch__marketsDropdownButton}
        dropdownStyle={styles.heroSearch__marketsDropdown}
        dropdownContent={marketsDropdown}
        withArrow={false}
      >
        {market?.name || (isMobile ? 'Location' : 'Choose Location')}
      </ButtonWithDropdown>

      <RangePicker
        value={dateRange}
        onRangeSelect={setDateRange}
        defaultFuzzyDatesOffset={fuzzyDatesOffset}
        onFuzzyDatesOffsetChange={setFuzzyDatesOffset}
        rangePickerContainerStyle={styles.heroSearch__dateRangeCalendar}
        containerStyle={styles.heroSearch__dateRangeContainer}
        noMobile
      />

      {isMobile ? (
        <ArrowButton
          className={styles.heroSearch__arrowButton}
          onClick={onSearchClick}
          bgFill="#1d1919"
          arrowFill="white"
        />
      ) : (
        <Button
          onClick={onSearchClick}
          containerStyle={styles.heroSearch__searchButton}
          variant="darkContained"
        >
          Search
        </Button>
      )}
    </div>
  );
};

export default HeroSearch;
