import React from 'react';

import { CMSHomepageType } from '@utils/types/cmsHomepage';
import ImageWithLoader from '@components/_shared/imageWithLoader';
import LandingAutoScrollableGallery from '@components/_shared/landingAutoScrollableGallery';

import styles from './styles.module.scss';

type RoveDifferenceType = {
  title: CMSHomepageType['roveDifferenceTitle'];
  description: CMSHomepageType['roveDifferenceDescription'];
  roveDifferenceSlider: CMSHomepageType['roveDifferenceSlider'];
};

const RoveDifference = (props: RoveDifferenceType) => {
  const { title, description, roveDifferenceSlider } = props;

  return (
    <div className={styles.roveDifference}>
      <h2 className={styles.roveDifference__title}>{title}</h2>
      <div className={styles.roveDifference__description}>{description}</div>

      {roveDifferenceSlider && (
        <LandingAutoScrollableGallery
          uniqueKey="rove-difference"
          items={roveDifferenceSlider}
          containerStyle={styles.roveDifference__slider}
          renderItem={(i: any, isCurrent: boolean) => (
            <div className={styles.roveDifferenceCard}>
              {i.image && (
                <ImageWithLoader
                  containerStyle={styles.roveDifferenceCard__image}
                  src={i.image}
                  alt={i.subtitle}
                  sizes="(max-width: 1024px) 100vw,
                       50vw"
                />
              )}
              <div className={styles.roveDifferenceCard__footer}>
                <div className={styles.roveDifferenceCard__title}>
                  {i.title}
                </div>
                <div className={styles.roveDifferenceCard__subtitle}>
                  {i.subtitle}
                </div>
              </div>
            </div>
          )}
        />
      )}
    </div>
  );
};

export default RoveDifference;
