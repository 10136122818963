import React, { useEffect, useMemo, useState } from 'react';

import {
  SegmentEventNamesEnum,
  SegmentEventPagesEnum,
  useAnalytics,
} from '@utils/hooks/useAnalytics';
import { MarketType } from '@utils/types/city';
import Tabs from '@components/_shared/tabs';
import ListingCard, { ListingInfoForCard } from './listingCard';
import Button from '@components/_shared/button';

import styles from './styles.module.scss';

export type ListingsByMarketType = {
  listings: Record<string, ListingInfoForCard[]>;
  markets: MarketType[];
};

const ListingsByMarket = (props: ListingsByMarketType) => {
  const { listings, markets } = props;
  const [selectedLocation, setSelectedLocation] = useState<string>();

  const { trackEvent } = useAnalytics();

  const places = useMemo(
    () =>
      !!listings
        ? Object.keys(listings).map((k) => ({
            id: k,
            label: markets.find((m) => m.slug === k)?.name || k,
            value: k,
            onClick: () => {
              setSelectedLocation(k);
              trackEvent(SegmentEventNamesEnum.FEATURED_LISTING_TAB_CLICKED, {
                PageName: SegmentEventPagesEnum.HOME_PAGE,
              });
            },
          }))
        : [],
    [JSON.stringify(listings)]
  );

  const listingsList = useMemo(
    () =>
      (selectedLocation ? listings[selectedLocation] : []).map((l, key) => (
        <ListingCard
          listing={l}
          key={`listing-${l.url_slug}-${selectedLocation}-${key}`}
          containerStyle={styles.propertiesContainer__listing}
        />
      )),
    [selectedLocation]
  );

  useEffect(() => {
    if (!selectedLocation && places.length !== 0) {
      setSelectedLocation(places[0].id);
    }
  }, [places, selectedLocation]);

  return (
    <section className={styles.listingsByMarkets}>
      <Tabs
        tabs={places}
        initialTab={selectedLocation}
        containerStyle={styles.listingsByMarkets__tabs}
      />

      <div className={styles.listingsByMarkets__properties}>{listingsList}</div>

      <Button
        containerStyle={styles.listingsByMarkets__button}
        to={`/search?market=${selectedLocation}`}
        variant="tanOutlinedTransparent"
      >
        Show map
      </Button>
    </section>
  );
};

export default ListingsByMarket;
