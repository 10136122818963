import React from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import { BaseComponentProps } from '@utils/types/baseComponents';
import { ArrowRight } from '@assets/icons';
import { colors } from '@utils/constants/colors';

import styles from './styles.module.scss';

type Props = {
  href: any;
} & BaseComponentProps;

const DiscoverLink = (props: Props) => {
  const { href, theme = 'dark' } = props;

  return (
    <Link href={href}>
      <a
        className={clsx([
          styles.discoverLink,
          'with-opacity-hover',
          styles[`discoverLink__${theme}`],
        ])}
      >
        Discover
        <ArrowRight
          stroke={theme === 'dark' ? colors.roveWhite : colors.roveBlack}
        />
      </a>
    </Link>
  );
};

export default DiscoverLink;
