import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import _isUndefined from 'lodash/isUndefined';

import ImageWithLoader from '@components/_shared/imageWithLoader';
import AvailableFrom from '@components/_shared/availableFrom';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { FeaturedListingType } from '@utils/types/featuredListing';
import { useHorizontalSwipes } from '@utils/hooks/useHorizontalSwipes';
import { ArrowInCircle } from '@assets/icons';
import { Rating } from '@components/_shared/rating';
import { colors } from '@utils/constants/colors';

import styles from './styles.module.scss';

type Props = {
  photos: FeaturedListingType['photos'];
  first_available_date?: string | null;
  rating?: number;
  onClick?: () => void;
} & BaseComponentProps;

const isInProximity = (currentIndex: number, index: number) => {
  return Math.abs(currentIndex - index) <= 2;
};

const ListingImagesCarousel = (props: Props) => {
  const { photos, first_available_date, rating, onClick, containerStyle } =
    props;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const dotsWrapperRef = useRef<HTMLDivElement>(null);

  const { onTouchStart, onTouchMove, onTouchEnd } = useHorizontalSwipes(
    () =>
      setCurrentImageIndex((val) => (val === photos.length - 1 ? 0 : val + 1)),
    () =>
      setCurrentImageIndex((val) => (val === 0 ? photos.length - 1 : val - 1))
  );

  const onArrowClick = (i: number) => {
    switch (i) {
      case -1:
        setCurrentImageIndex((prevState) =>
          prevState === 0 ? photos.length - 1 : prevState - 1
        );

        if (currentImageIndex === 0) {
          dotsWrapperRef.current?.scrollTo({
            left: dotsWrapperRef.current.scrollWidth,
            behavior: 'smooth',
          });
        } else {
          dotsWrapperRef.current?.scrollTo({
            left: dotsWrapperRef.current.scrollLeft - 9.5,
            behavior: 'smooth',
          });
        }

        break;
      case 1:
      default:
        setCurrentImageIndex((prevState) =>
          prevState === photos.length - 1 ? 0 : prevState + 1
        );

        if (currentImageIndex >= 0 && currentImageIndex < 4) {
          break;
        }

        if (currentImageIndex === photos.length - 1) {
          dotsWrapperRef.current?.scrollTo({
            left: 0,
            behavior: 'smooth',
          });
        } else {
          dotsWrapperRef.current?.scrollTo({
            left: dotsWrapperRef.current.scrollLeft + 9.5,
            behavior: 'smooth',
          });
        }

        break;
    }
  };

  return (
    <div
      className={clsx([containerStyle, styles.carouselWrapper])}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {!_isUndefined(rating) && (
        <Rating
          showAllStars
          color={colors.roveBlue}
          rate={rating}
          containerStyle={styles.rating}
        />
      )}

      {first_available_date && (
        <AvailableFrom
          first_available_date={first_available_date}
          containerStyle={styles.availableFrom}
        />
      )}

      {photos.length !== 1 && (
        <>
          <section
            className={styles.dotsWrapper}
            onClick={(e) => e.preventDefault()}
            ref={dotsWrapperRef}
            style={{
              maxWidth: currentImageIndex < 4 ? '58px' : '80px',
            }}
          >
            <div className={styles.dotsWrapper__container}>
              {photos.map((_, i) => (
                <div
                  key={i}
                  className={clsx([
                    styles.dot,
                    currentImageIndex === i && styles.active,
                    isInProximity(currentImageIndex, i) && styles.inProximity,
                  ])}
                />
              ))}
            </div>
          </section>

          <button
            className={styles.arrowOnHover}
            onClick={(e) => {
              e.preventDefault();
              onArrowClick(-1);
            }}
          >
            <ArrowInCircle />
          </button>

          <button
            className={styles.arrowOnHover}
            onClick={(e) => {
              e.preventDefault();
              onArrowClick(1);
            }}
          >
            <ArrowInCircle />
          </button>
        </>
      )}

      <ImageWithLoader
        containerStyle={styles.image}
        src={photos[currentImageIndex]}
        alt="Listing image"
        sizes="(max-width: 1024px) 100vw,
                       25vw"
        onClick={onClick && onClick}
        priority
      />
    </div>
  );
};

export default ListingImagesCarousel;
