import { useIsMobile } from '@utils/hooks/useIsMobile';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { Star } from '@assets/icons';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

type Props = {
  rate: number;
  total: number;
  color: string;
  showDescription: boolean;
  showAllStars: boolean;
  onChange?: (rating: number) => void;
} & BaseComponentProps;

Rating.defaultProps = {
  total: 5,
  color: 'white',
  showDescription: false,
  showAllStars: false,
};

export function Rating(props: Props) {
  const {
    rate,
    containerStyle,
    color,
    total,
    showDescription,
    showAllStars,
    onChange,
  } = props;
  const isMobile = useIsMobile();
  const [stars, setStars] = useState<string[]>([]);

  useEffect(() => {
    setStars(
      Array.from(Array(Math.floor(total)).keys()).map((_, idx) =>
        idx < rate ? color : 'transparent'
      )
    );
  }, [rate]);

  return (
    <div className={clsx([styles.container, containerStyle])}>
      {isMobile && !showAllStars ? (
        <span>
          <Star className={styles.star} /> {rate} out of {total}
        </span>
      ) : (
        stars.map((v, idx) => (
          <Star
            key={idx}
            fill={v}
            stroke={color}
            onClick={() => onChange && onChange(idx + 1)}
            style={{ cursor: onChange ? 'pointer' : 'unset' }}
          />
        ))
      )}
      {showDescription && (
        <div className={styles.description}>{rate} stars</div>
      )}
    </div>
  );
}
