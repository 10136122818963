import { useRouter } from 'next/router';

type UseGetListingLinkProps = {
  listingSlug?: string;
  scrollto?: string;
  preserveQuery?: boolean;
  newQuery?: any;
};

type GetListingLinkReturn = {
  prepareListingHref: (props: UseGetListingLinkProps) => {
    pathname: string;
    query: any;
  };
};

/**
 * Get the listing link with preserved query params
 */
export function useGetListingLink(): GetListingLinkReturn {
  const { query } = useRouter();

  return {
    prepareListingHref: ({
      listingSlug,
      scrollto,
      preserveQuery,
      newQuery,
    }: UseGetListingLinkProps) => {
      return {
        pathname: `/listing/${listingSlug}`,
        query: {
          ...(preserveQuery ? query : newQuery || {}),
          ...(scrollto && { scrollto }),
        },
      };
    },
  };
}
