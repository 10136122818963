import React, { useMemo } from 'react';
import clsx from 'clsx';
import Link from 'next/link';

import ListingImagesCarousel from '@components/_shared/listingImagesCarousel';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { FeaturedListingType } from '@utils/types/featuredListing';
import {
  SegmentEventNamesEnum,
  SegmentEventPagesEnum,
  useAnalytics,
} from '@utils/hooks/useAnalytics';
import { listingTitleWithoutByRove } from '@utils/helpers';
import { Bedroom, Bathroom } from '@assets/icons';
import { useGetListingLink } from '@utils/hooks/useGetListingLink';

import styles from './styles.module.scss';

export type ListingInfoForCard = Pick<
  FeaturedListingType,
  | 'import_listing_id'
  | 'photos'
  | 'url_slug'
  | 'cms_listing_title'
  | 'title'
  | 'first_available_date'
  | 'bedrooms_total'
  | 'bathrooms'
>;

type Props = {
  listing: ListingInfoForCard;
} & BaseComponentProps;

const ListingCard = (props: Props) => {
  const { containerStyle, listing } = props;
  const { trackEvent } = useAnalytics();
  const { prepareListingHref } = useGetListingLink();

  const onListingClick = () => {
    trackEvent(SegmentEventNamesEnum.FEATURED_LISTING_CLICKED, {
      PageName: SegmentEventPagesEnum.HOME_PAGE,
    });
  };

  const listingPhotos = useMemo(
    () =>
      listing.photos.length ? (
        <ListingImagesCarousel
          photos={listing.photos}
          first_available_date={listing.first_available_date}
          containerStyle={styles.listingCard__imageContainer}
        />
      ) : null,
    [JSON.stringify(listing.photos), listing.first_available_date]
  );

  return (
    <div className={clsx([styles.listingCard, containerStyle])}>
      <Link
        href={prepareListingHref({
          listingSlug: listing.url_slug,
        })}
        passHref
      >
        <a onClick={onListingClick}>
          <div className={styles.listingCard__body}>{listingPhotos}</div>

          <div
            className={styles.listingCard__title}
            title={listing.cms_listing_title}
          >
            {listing.cms_listing_title ||
              listingTitleWithoutByRove(listing.title)}
          </div>

          <div className={styles.listingCard__rooms}>
            <Bedroom />
            {`${listing.bedrooms_total} Bedroom${
              Number(listing.bedrooms_total) > 1 ? 's' : ''
            }`}
            ,&nbsp;
            <Bathroom />
            {`${listing.bathrooms} Bathroom${
              Number(listing.bathrooms) > 1 ? 's' : ''
            }`}
          </div>
        </a>
      </Link>
    </div>
  );
};

export default ListingCard;
