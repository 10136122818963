import React from 'react';
import _omit from 'lodash/omit';
import _sortBy from 'lodash/sortBy';
import * as Sentry from '@sentry/nextjs';

import { getHomepageContent } from '@api/homepage/getHomepageContent';
import { getCollectionsByType } from '@api/collections/getCollectionsByType';
import {
  parseMarketsFromCollections,
  pickFieldsFromListingForByMarketView,
} from '@components/home/helpers';
import HeroSection from '@components/home/heroSection';
import MetaHead from '@components/_shared/metaHead';
import { ListingInfoForCard } from '@components/home/listingsByMarket/listingCard';
import { withLayoutAndSession } from '@utils/hocs';
import ListingsByMarket, {
  ListingsByMarketType,
} from '@components/home/listingsByMarket';
import RoveDifference from '@components/home/roveDifference';
import ThreeFeaturedProperties from '@components/home/threeFeaturedProperties';
import GuestAppPreview from '@components/home/guestAppPreview';
import PressReviews from '@components/home/pressReviews';
import FeaturedProperties from '@components/home/featuredProperties';
import { CMSHomepageType } from '@utils/types/cmsHomepage';
import { PageWithProvidersAndLayout } from '@utils/types/nextApp';
import { MarketType } from '@utils/types/city';
import { searchListingsRequest } from '@api/listings/searchListingsRequest';

import styles from './styles.module.scss';

type Props = {
  cmsHomepage: CMSHomepageType;
  markets: MarketType[];
  listingsByMarket: ListingsByMarketType['listings'];
};

type StaticProps = {
  props: Props;
  revalidate: Number;
};

const Home: PageWithProvidersAndLayout<Props> = (props: Props) => {
  const { markets, listingsByMarket, cmsHomepage } = props;

  return (
    <>
      <MetaHead
        title={cmsHomepage.metaTitle as string}
        description={cmsHomepage.metaDescription as string}
        ogImage="https://www.rovetravel.com/images/homepage-for-og.jpg"
        canonical="https://www.rovetravel.com/"
      />

      <div className={styles.home}>
        <HeroSection
          title={cmsHomepage.title}
          subtitle={cmsHomepage.subtitle}
          markets={markets}
        />

        <ListingsByMarket listings={listingsByMarket} markets={markets} />

        <RoveDifference
          title={cmsHomepage.roveDifferenceTitle}
          description={cmsHomepage.roveDifferenceDescription}
          roveDifferenceSlider={cmsHomepage.roveDifferenceSlider}
        />

        <GuestAppPreview
          guestAppPreview={cmsHomepage.guestAppPreview}
          guestAppPreviewImage1={cmsHomepage.guestAppPreviewImage1}
          guestAppPreviewImage2={cmsHomepage.guestAppPreviewImage2}
        />

        <ThreeFeaturedProperties
          title={cmsHomepage.threeFeaturedPropertiesTitle}
          description={cmsHomepage.threeFeaturedPropertiesDescription}
          listings={cmsHomepage.threeFeaturedPropertiesListings}
        />

        <PressReviews {...cmsHomepage.pressReviews} />

        {cmsHomepage.featuredProperties?.length && (
          <FeaturedProperties properties={cmsHomepage.featuredProperties} />
        )}
      </div>
    </>
  );
};

export async function getStaticProps(): Promise<StaticProps | undefined> {
  try {
    const cmsHomepage = await getHomepageContent();
    const collections = await getCollectionsByType(['market']);

    const markets = parseMarketsFromCollections(collections);

    const listingsByMarket: Record<string, ListingInfoForCard[]> = {};

    for (const m of markets) {
      const guestyIds: string[] = [];

      if (m.relatedListings) {
        m.relatedListings.forEach((el) => {
          if (el?.guestyId) guestyIds.push(el.guestyId);
        });
      }

      const { listings } = await searchListingsRequest({
        guestyIds: guestyIds.slice(0, 8),
      });

      if (listings.length) {
        listingsByMarket[m.slug] = _sortBy(listings, (l) =>
          m.relatedListings.findIndex(
            (rl) => rl.guestyId === l.import_listing_id
          )
        ).map(pickFieldsFromListingForByMarketView);
      }
    }

    return {
      props: {
        cmsHomepage,
        markets: markets.map((m) => _omit(m, ['relatedListings'])),
        listingsByMarket,
      },
      revalidate: 30,
    };
  } catch (error) {
    Sentry.captureException(error);
  }
}

export default withLayoutAndSession(Home, {
  headerProps: { theme: 'dark' },
  footerProps: { theme: 'dark', noParallaxImage: true },
});
