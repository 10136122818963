import React from 'react';
import Link from 'next/link';

import { CMSHomepageType } from '@utils/types/cmsHomepage';
import ImageWithLoader from '@components/_shared/imageWithLoader';
import DiscoverLink from '@components/home/discoverLink';
import { useGetListingLink } from '@utils/hooks/useGetListingLink';

import styles from './styles.module.scss';

type ThreeFeaturedPropertiesType = {
  title: CMSHomepageType['threeFeaturedPropertiesTitle'];
  description: CMSHomepageType['threeFeaturedPropertiesDescription'];
  listings: CMSHomepageType['threeFeaturedPropertiesListings'];
};

const ThreeFeaturedProperties = (props: ThreeFeaturedPropertiesType) => {
  const { title, description, listings } = props;
  const { prepareListingHref } = useGetListingLink();

  if (!listings?.length) return null;

  return (
    <div className={styles.threeFeaturedProperties}>
      <div className={styles.threeFeaturedProperties__textBlock}>
        <h2 className={styles.threeFeaturedProperties__title}>{title}</h2>

        <div className={styles.threeFeaturedProperties__description}>
          {description}
        </div>
      </div>

      <div className={styles.threeFeaturedProperties__wrapper}>
        {listings.map((l) => {
          const href = prepareListingHref({
            listingSlug: l.listing ? l.listing[0]?.url_slug : '',
          });
          const title =
            l.title || (l.listing ? l.listing[0]?.listingTitle : '');
          return (
            <div
              key={l._key}
              className={styles.threeFeaturedProperties__listing}
            >
              {l.image && (
                <Link href={href}>
                  <a className={styles.listingImage}>
                    <ImageWithLoader
                      src={l.image}
                      alt={l.title}
                      containerStyle={styles.listingImage__image}
                      sizes="(max-width: 1024px) 100vw,
                       50vw"
                    />
                  </a>
                </Link>
              )}

              <div className={styles.listingFooter}>
                <div className={styles.listingFooter__title}>{title}</div>

                <DiscoverLink href={href} theme="dark" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ThreeFeaturedProperties;
