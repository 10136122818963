import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { CMSHomepageType } from '@utils/types/cmsHomepage';
import DiscoverLink from '@components/home/discoverLink';
import { useGetListingLink } from '@utils/hooks/useGetListingLink';

import styles from './styles.module.scss';

type FeaturedPropertiesType = {
  properties: CMSHomepageType['featuredProperties'];
};

const FeaturedProperties = (props: FeaturedPropertiesType) => {
  const { properties } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const { prepareListingHref } = useGetListingLink();

  const activeProperty = properties?.[activeIndex];

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (properties?.length) {
      timeout = setTimeout(() => {
        setActiveIndex((prevIndex) =>
          prevIndex === properties.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [activeIndex]);

  return (
    <div
      className={clsx([styles.featuredProperties, 'with-parallax'])}
      style={{ backgroundImage: `url(${activeProperty.image || ''})` }}
    >
      <div className={styles.featuredProperties__bg} />

      <div className={styles.featuredProperties__footer}>
        <div className={styles.dotsBlock}>
          {properties?.map((property, index) => (
            <div
              key={property._key}
              className={clsx([styles.dotsBlock__dot], {
                [styles.dotsBlock__dotActive]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </div>

        <div className={styles.discoverBlock}>
          <div className={styles.discoverBlock__title}>
            {activeProperty.title ||
              (activeProperty.listing
                ? activeProperty.listing[0]?.listingTitle
                : '')}
          </div>

          <DiscoverLink
            href={prepareListingHref({
              listingSlug: activeProperty.listing
                ? activeProperty.listing[0]?.url_slug
                : '',
            })}
            theme="light"
          />
        </div>
      </div>
    </div>
  );
};

export default FeaturedProperties;
